/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { CenterContent, IconButton, Portal, PositionedPortal, } from "@enfusion-ui/web-components";
import { styled, ThisTabProvider, useContextMenu, useIsTabletDevice, useTabs, useTheme, } from "@enfusion-ui/web-core";
import { faEllipsisH, faTimes, } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Actions, Layout } from "flexlayout-react";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";
import { useLatest } from "react-use";
//#region Styled Elements
const TabLayoutContainer = styled.div `
  flex: 1;
  height: 100%;
  min-width: 0;
  position: relative;
`;
const Tabs = styled.div `
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${({ empty }) => empty
    ? `background-color: var(--background-color-2);
  background-image: var(--landing-bg);
  background-attachment: fixed;
  background-size: cover;`
    : ""}

  ::-webkit-scrollbar-thumb {
    border: 8px solid transparent;
    border-radius: 8px;
    background-color: var(--background-accent);
  }
`;
const TabPanels = styled.div `
  overflow: hidden;
  height: ${({ empty }) => empty ? "100%" : "calc(100% - 40px)"};
  position: relative;
`;
const TabPanel = styled.div `
  display: ${({ active }) => (active ? "block" : "none")};
  overflow: hidden;
  height: 100%;
`;
const TabLabel = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
  display: flex;
  align-items: center;
`;
const TabTopBar = styled.div `
  height: 38px;
  display: ${({ empty }) => (empty ? "none" : "flex")};
  flex-direction: row;
  min-width: 0;
  overflow: hidden;
  background-color: var(--background-color-0);
`;
const TabListContainer = styled.div `
  overflow: hidden;
  flex: 1;
`;
const TabList = styled.div `
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  background-color: var(--background-color-0);
  padding-bottom: 8px;
  overflow-y: hidden;
  overflow-x: auto;
  box-sizing: content-box;
`;
const Tab = styled.div `
  font-size: 12px;
  padding-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid var(--background-color-2);
  min-width: min-content;
  height: 38px;
  cursor: pointer;
  background: ${({ active }) => active ? "var(--background-color-2)" : "transparent"};
`;
const WelcomeCard = styled.div `
  background: var(--background-color-0);
  padding: 40px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: grid;
  grid-gap: 24px;
  width: 420px;
  max-width: 100%;
  box-sizing: border-box;
`;
const WelcomeCardContent = styled.div `
  display: flex;
`;
const MenuButton = styled(IconButton) `
  background-color: var(--background-accent);
  border-radius: 0px;
`;
const MenuItemText = styled.div `
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TabTitle = ({ node, onContext, title }) => {
    const { theme } = useTheme();
    const name = title || node?.getName() || "";
    const titleFactoryRef = React.useRef(null);
    const handleMouseDown = (e) => {
        // if its right click
        if (e.button === 2) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const handleContext = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onContext({
            node,
            clientX: e.nativeEvent.clientX,
            clientY: e.nativeEvent.clientY,
            ref: titleFactoryRef,
        });
    }, [onContext]);
    return (React.createElement("div", { ref: titleFactoryRef, onContextMenu: handleContext, onMouseDown: handleMouseDown, "data-testid": createTestId(`tab-layout-title-${name}`) },
        React.createElement("span", { style: {
                color: theme.key === "dracula" ? "var(--info)" : undefined,
            }, title: name }, name)));
};
const TabIcon = ({ node, onContext }) => {
    const icon = node.getIcon();
    const iconFactoryRef = React.useRef(null);
    const handleMouseDown = (e) => {
        // if its right click
        if (e.button === 2) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const handleContext = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onContext({
            node,
            clientX: e.nativeEvent.clientX,
            clientY: e.nativeEvent.clientY,
            ref: iconFactoryRef,
        });
    }, [onContext]);
    if (icon) {
        return (React.createElement("div", { ref: iconFactoryRef, onContextMenu: handleContext, onMouseDown: handleMouseDown, style: { display: "inline-block" } },
            React.createElement(FontAwesomeIcon, { icon: icon, style: { marginRight: 8 }, color: "var(--tab-icon-color-0)" })));
    }
    return null;
};
export const TabLayout = ({ renderTab, emptyMessage, tutorial }) => {
    const { tabs, closeTab, selectedTabId, focusTab, layoutModel, undockTab } = useTabs();
    const menuIconRef = React.useRef(null);
    const isTabletDevice = useIsTabletDevice();
    const closeTabRef = useLatest(closeTab);
    React.useEffect(() => {
        if (typeof selectedTabId !== "undefined") {
            layoutModel?.doAction(Actions.selectTab(selectedTabId));
        }
    }, [selectedTabId]);
    //#region tab ref
    const tabRefs = React.useRef({});
    const handleTabChange = (id) => () => {
        focusTab(id);
        tabRefs.current[id].scrollIntoView();
    };
    const tabRef = (id) => (instance) => {
        tabRefs.current[id] = instance;
    };
    const empty = tabs.length === 0;
    //#endregion tab ref
    //#region tab list
    const [menuOpen, setMenuOpen] = React.useState(false);
    const closeMenu = () => setMenuOpen(false);
    const handleMenuClick = ({ key }) => {
        closeMenu();
        handleTabChange(key)();
    };
    const openMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOpen(true);
    };
    //#endregion tab list
    //#region context menu control
    const { contextMenuNode, contextMenuOpen, closeContextMenu, openContextMenu, handleContextMenuPlacement, } = useContextMenu();
    const setTabContext = (contextNode) => {
        openContextMenu(contextNode);
    };
    //#endregion context menu control
    //#region tab context actions
    const handleCloseAll = useRefCallback(() => {
        for (const tab of tabs) {
            closeTab(tab.id);
        }
        closeContextMenu();
    }, [tabs]);
    const handleCloseOthers = useRefCallback(() => {
        if (contextMenuNode) {
            for (const tab of tabs) {
                if (tab.id !== contextMenuNode.node.getId()) {
                    closeTab(tab.id);
                }
                else {
                    focusTab(tab.id);
                }
            }
        }
        closeContextMenu();
    }, [contextMenuNode, tabs]);
    const handleUndockIgnored = useRefCallback(() => {
        if (contextMenuNode) {
            undockTab(contextMenuNode.node.getId());
        }
    }, [contextMenuNode, undockTab]);
    //#endregion tab context actions
    //#region tab factories
    const titleFactory = React.useCallback((node) => {
        return React.createElement(TabTitle, { node: node, onContext: setTabContext });
    }, [setTabContext]);
    const iconFactory = React.useCallback((node) => {
        return React.createElement(TabIcon, { node: node, onContext: setTabContext });
    }, [setTabContext]);
    const factory = (node) => {
        const component = node.getComponent();
        if (component) {
            const def = {
                id: node.getId(),
                name: node.getName(),
                component: component,
                config: (tabs && tabs.find(({ id }) => id === node.getId())?.config) ||
                    node.getConfig(),
            };
            return (React.createElement(ThisTabProvider, { id: def.id, config: def.config }, renderTab(def)));
        }
    };
    const handleLayoutAction = (action) => {
        if (action.type === Actions.DELETE_TAB) {
            closeTabRef.current(action.data.node);
            return undefined;
        }
        else if (action.type === Actions.SELECT_TAB) {
            focusTab(action.data.tabNode);
            return undefined;
        }
        return action;
    };
    //#endregion tab factories
    if (isTabletDevice) {
        return (React.createElement(TabLayoutContainer, { "data-testid": createTestId("tablet-tab-layout-container") },
            React.createElement(Tabs, { empty: empty },
                React.createElement(TabTopBar, { empty: empty },
                    React.createElement(TabListContainer, null,
                        React.createElement(TabList, null, tabs.reduce((res, { name, id, icon, ...rest }) => {
                            return [
                                ...res,
                                React.createElement(Tab, { key: id, ref: tabRef(id), active: id === selectedTabId, onClick: handleTabChange(id) },
                                    React.createElement(TabLabel, { title: name },
                                        icon && (React.createElement(FontAwesomeIcon, { icon: icon, style: { marginRight: 8 } })),
                                        React.createElement(TabTitle, { node: {
                                                ...rest,
                                                name,
                                                icon,
                                                getId: () => id,
                                            }, title: name, onContext: setTabContext })),
                                    React.createElement(IconButton, { icon: faTimes, onClick: (event) => {
                                            event.stopPropagation();
                                            closeTab(id);
                                        } })),
                            ];
                        }, []))),
                    React.createElement(MenuButton, { title: "Tab List", icon: faEllipsisH, ref: menuIconRef, onClick: openMenu }),
                    React.createElement(Portal, { open: menuOpen, attachedRef: menuIconRef, onClickOutside: closeMenu, align: "right" },
                        React.createElement(Menu, { selectedKeys: [] }, tabs.map(({ name, id, icon }) => (React.createElement(MenuItem, { key: id, onClick: handleMenuClick },
                            icon && (React.createElement(FontAwesomeIcon, { icon: icon, style: { marginRight: 4 } })),
                            React.createElement(MenuItemText, null, name))))))),
                React.createElement(TabPanels, { empty: empty },
                    empty ? (React.createElement(CenterContent, null,
                        React.createElement(WelcomeCard, null,
                            React.createElement(WelcomeCardContent, null, emptyMessage)))) : null,
                    tabs.map((def) => {
                        return (React.createElement(TabPanel, { id: `tab-${def.id}`, key: def.id, active: def.id === selectedTabId },
                            React.createElement(ThisTabProvider, { id: def.id, config: def.config }, renderTab(def))));
                    })))));
    }
    return (React.createElement(TabLayoutContainer, { "data-testid": createTestId("tab-layout-container"), "data-tutorial-step": tutorial ? `${tutorial}__tabs` : undefined },
        empty || !layoutModel ? (React.createElement(Tabs, { empty: empty },
            React.createElement(TabPanels, { empty: empty },
                React.createElement(CenterContent, null,
                    React.createElement(WelcomeCard, null,
                        React.createElement(WelcomeCardContent, { "data-testid": createTestId("welcome-card-empty-message") }, emptyMessage)))))) : (React.createElement(Layout, { model: layoutModel, factory: factory, iconFactory: iconFactory, titleFactory: titleFactory, onAction: handleLayoutAction })),
        React.createElement(PositionedPortal, { key: contextMenuNode?.node.getId(), open: contextMenuOpen, setPlacement: handleContextMenuPlacement, onClickOutside: closeContextMenu },
            React.createElement(Menu, { onClick: closeContextMenu, selectedKeys: [] },
                React.createElement(MenuItem, { key: "un-dock", onClick: handleUndockIgnored }, "Undock"),
                React.createElement(MenuItem, { key: "close-all", onClick: handleCloseAll }, "Close All"),
                React.createElement(MenuItem, { key: "close-others", onClick: handleCloseOthers }, "Close Others")))));
};
